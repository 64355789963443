
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useForm } from "vee-validate";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { User } from "@/core/enums/UserEnums";


export default defineComponent({
  name: "new-form-modal",
  emits: ['onModalSubmit', 'resetModal'],
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    modalName: {
      type: String,
      required: true
    },
    validationSchema: {
      type: Object
    },
  },
  components: {

  },
  setup(props, { emit }) {
    const { values, resetForm, validate } = useForm({
      validationSchema: props.validationSchema,
      initialValues: {
        [User.isActive]: 0
      }
    });
    // const { resetForm } = useForm();
    const modal = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);

    const handleSubmit = async () => {
      const { valid } = await validate();
      if (submitButton.value) {
        loading.value = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      if (valid) {
        emit('onModalSubmit', values);
        loading.value = false;
        submitButton.value?.removeAttribute("data-kt-indicator");
        return;
      }
      loading.value = false;
      submitButton.value?.removeAttribute("data-kt-indicator");

    }

    onMounted(() => {
      modal.value?.addEventListener('hidden.bs.modal', () => {
        emit('resetModal', resetForm)
        removeModalBackdrop();
      })
    });

    onUnmounted(() => {
      modal.value?.removeEventListener('hidden.bs.modal', () => {
        emit('resetModal', resetForm)
        removeModalBackdrop();
      })
    })

    return {
      modal,
      handleSubmit,
      submitButton,
      loading
    }
  },
});
