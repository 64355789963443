
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "AgGridButton",
  props: ['params'],
  setup(props) {
    const value = ref(props.params.value);

    const buttonHandler = () => {
      props.params.clicked(props.params)
    }

    const isCancelBeforeStart = () => {
      return false;
    };

    const getValue = () => {
      return value.value.click(props.params.value)
    };

    return {
      getValue,
      isCancelBeforeStart,
      value,
      buttonHandler
    };
  },
});
