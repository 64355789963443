import { ref } from "vue";
import { useStore } from "vuex";
import { User } from "@/core/enums/UserEnums";
import { Actions as AdminActions } from "@/store/enums/AdminEnums";
import { hideModal } from "@/core/helpers/dom";
import { CreateUser as UserInterface } from "@/core/interfaces/UserInterface";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useForm } from "vee-validate";

export const ManageUsersModalSetup = () => {
  const store = useStore();
  const submitButtonRef = ref<null | HTMLButtonElement>(null);
  const loading = ref<boolean>(false);
  // const { handleSubmit, resetForm } = useForm();

  const defaultUserData = ref<UserInterface>({
    [User.firstName]: "",
    [User.lastName]: "",
    [User.isActive]: 1,
    email: "",
    roles: [],
  });

  const newUserData = ref<UserInterface>({
    [User.firstName]: "",
    [User.lastName]: "",
    [User.isActive]: 1,
    email: "",
    roles: [],
  });

  const modalValidationSchema = Yup.object().shape({
    [User.firstName]: Yup.string()
      .required()
      .label("First Name"),
    [User.lastName]: Yup.string()
      .required()
      .label("Last Name"),
    email: Yup.string()
      .required()
      .label("Email"),
    [User.isActive]: Yup.boolean()
      .oneOf([true, false])
      .label("Activation Status"),
  });

  const handleReset = (params) => {
    console.log("reset", params);
    params();
  };

  const onSubmitUser = async () => {
    console.log("user submitted!");
    const payload = {
      [User.firstName]: newUserData.value.first_name,
      [User.lastName]: newUserData.value.last_name,
      [User.email]: newUserData.value.email,
      [User.isActive]: newUserData.value.is_active,
    };
    console.log(payload);

    if (!submitButtonRef.value) {
      return;
    }

    loading.value = true;

    // Activate indicator
    submitButtonRef.value?.setAttribute("data-kt-indicator", "on");

    store.dispatch(AdminActions.CREATE_USER, payload).then(() => {
      hideModal(`kt_modal_new_user`).then(() => {
        newUserData.value = defaultUserData.value;
      });
      Swal.fire({
        text: "Form has been successfully submitted!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then(() => {
        loading.value = false;
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;
          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }
        // window.location.reload();
      });
    });
  };

  return {
    newUserData,
    modalValidationSchema,
    onSubmitUser,
    submitButtonRef,
    loading,
    handleReset,
  };
};
