import { ref, watchEffect, watch, onMounted, onUpdated, reactive } from "vue";
import { User } from "@/core/interfaces/UserInterface";
import { User as UserEnums } from "@/core/enums/UserEnums";
import * as Yup from "yup";
// import { useForm } from "vee-validate";

export const ManageUsersAsideSetup = (selectedUser) => {
  // const { resetForm } = useForm();

  const availableRoles = ref([
    {
      value: 1,
      label: "Admin",
    },
    {
      value: 2,
      label: "Editor",
    },
    {
      value: 3,
      label: "Author",
    },
    {
      value: 4,
      label: "Customer",
    },
  ]);
  const multiSelectMsg = ref("");
  const userRoles = ref({
    mode: "tags",
    value: [],
    options: availableRoles.value,
    searchable: true,
    createTag: true,
    trackBy: "value",
    label: "label",
    object: true,
    required: true,
  });

  const asideValidationSchema = Yup.object().shape({
    [UserEnums.firstName]: Yup.string()
      .required()
      .label("First Name"),
    [UserEnums.lastName]: Yup.string()
      .required()
      .label("Last Name"),
    email: Yup.string()
      .required()
      .label("Email"),
    roles: Yup.string()
      .required()
      .label("Roles"),
    [UserEnums.isActive]: Yup.number()
      .oneOf([0, 1])
      .label("Activation Status"),
  });

  const handleClose = () => {
    if (userRoles.value.value.length === 0) {
      multiSelectMsg.value = "Roles is a required field. ";
    } else {
      multiSelectMsg.value = "";
    }
  };

  const handleReset = () => {
    // resetForm();
  };

  const saveEdit = () => {
    console.log("editUserData");
  };

  return {
    saveEdit,
    userRoles,
    asideValidationSchema,
    handleClose,
    multiSelectMsg,

    handleReset,
  };
};
