<template>
  <div class="h-100" v-if="value !== null">
    <!--begin::Switch-->
    <label
      id="action-switch-label"
      class="form-check form-switch form-check-custom form-check-solid form-check-label h-100"
    >
      <!--begin::Input-->
      <input
        :disabled="params.disabled"
        id="action-switch-input"
        class="form-check-input"
        name="switch-action"
        type="checkbox"
        @click="handleCheckbox"
        :checked="switchValue"
      />
      <!--end::Input-->
    </label>
    <!--end::Switch-->
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, onBeforeMount, ref } from "vue";

export default defineComponent({
  name: "AgGridSwitch",
  props: ['params'],
  setup(props) {
    const switchValue = ref<boolean | number>(props.params.value);
    const value = ref(props.params.value);
    console.log(value.value)

    const handleCheckbox = (e) => {
      if (e.target.checked) {
        switchValue.value = 1;
      } else {
        switchValue.value = 0;
      }
    };

    const isCancelBeforeStart = () => {
      return false;
    };

    const getValue = () => {
      return switchValue.value;
    };

    return {
      // status,
      handleCheckbox,
      getValue,
      switchValue,
      isCancelBeforeStart,
      value,
      // data,
    };
  },
});
</script>

<style>
#action-switch-label {
  justify-content: center;
}
</style>