
import { defineComponent, onMounted, ref, watch, watchEffect } from "vue";
import { useForm } from "vee-validate";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import Dropdown4 from "@/components/dropdown/Dropdown4.vue";
import { removeDrawerBackdrop } from "@/core/helpers/dom";


export default defineComponent({
  name: "EditFormAside",
  emits: ['onEditAsideSubmit', 'onEditAsideDelete'],
  props: {
    asideName: {
      type: String,
      required: true,
    },
    asideTitle: {
      type: String,
      required: true
    },
    selectedUser: {
      type: Object,
      required: false
    },
    validationSchema: {
      type: Object
    },
  },
  components: {
    Dropdown4,

  },
  setup(props, { emit }) {
    const { values, setValues, handleReset, validate } = useForm({
      validationSchema: props.validationSchema,
    });
    // setValues({ ...props.selectedUser })

    const editAsideRef = ref<null | HTMLElement>(null);
    const ktDrawerRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);


    const handleSubmit = async () => {
      const { valid } = await validate();
      if (submitButton.value) {
        loading.value = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      if (valid) {
        emit('onEditAsideSubmit', values)
        loading.value = false;
        submitButton.value?.removeAttribute("data-kt-indicator");

      }
    }

    const handleDelete = () => {
      emit('onEditAsideDelete', values)
    }

    onMounted(() => {
      if (!editAsideRef.value || !ktDrawerRef.value) {
        return;
      }

      DrawerComponent.createInstances(`#kt_drawer_${props.asideName}`)

      const drawer = DrawerComponent.getInstance(ktDrawerRef.value)
      console.log(drawer)
      drawer.on('kt.drawer.show', () => {
        setValues({ ...props.selectedUser })
      })

      drawer.on('kt.drawer.after.hidden', () => {
        handleReset();
        removeDrawerBackdrop();
      })

      if (ScrollComponent.getInstance(editAsideRef.value)) {
        ScrollComponent.getInstance(editAsideRef.value).update();
      }
    });

    return {
      editAsideRef,
      handleSubmit,
      handleDelete,
      ktDrawerRef,
      submitButton
    };
  },
});
