enum User {
  userId = "user_id",
  emailVerifiedAt = "email_verified_at",
  kerberosUsername = "kerberos_username",
  ntlmUsername = "ntlm_username",
  displayName = "display_name",
  langId = "lang_id",
  companyId = "company_id",
  firstName = "first_name",
  lastName = "last_name",
  email = "email",
  accountType = "type",
  isActive = "is_active",
}

export { User };
