<template>
  <button
    id="kt_drawer_edit_toggle"
    class="btn btn-sm btn-warning"
    @click="buttonHandler"
    data-bs-toggle="tooltip"
    data-bs-trigger="click"
  >Edit</button>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "AgGridButton",
  props: ['params'],
  setup(props) {
    const value = ref(props.params.value);

    const buttonHandler = () => {
      props.params.clicked(props.params)
    }

    const isCancelBeforeStart = () => {
      return false;
    };

    const getValue = () => {
      return value.value.click(props.params.value)
    };

    return {
      getValue,
      isCancelBeforeStart,
      value,
      buttonHandler
    };
  },
});
</script>

<style scoped>
.btn {
  z-index: 9999;
}
</style>