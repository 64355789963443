/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
// import DATA from "@/assets/json/olympics.json";

import store from "@/store";
import { Actions as AggridActions } from "@/store/enums/AggridEnums";

/**
 * Saves page state to the database
 * @param {object} state
 * state {
 *  state: {object},
 *  pagename: {string}
 * }
 */

export const saveState = async (state) => {
  console.log("saving state");
  // event.preventDefault();
  try {
    await store.dispatch(AggridActions.SAVE_TABLESTATE_ACTION, state);
  } catch (error) {
    console.log(error);
    store.dispatch(AggridActions.SET_UNDO_ACTION, {
      type: "error",
      title: error,
      buttonTitle: "Undo",
      allowUndo: false,
      duration: 3300,
      api: null,
    });
  }
};
