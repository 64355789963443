<template>
  <AgGridFP
    id="table1"
    ag-theme="ag-theme-material"
    widget-classes="card-xxl-stretch mb-xl-3"
    @gridApiReady="setGridApi"
    @colApiReady="setColApi"
    @displayedColumnsChanged="handleSaveState"
    :enable-card-header="true"
    :aggrid-data="testData"
    :custom-column-defs="customColumnDefs"
    :custom-body-options="bodyOptions"
    :framework-components="frameworkComponents"
    :enable-row-group="true"
    :enableStatusBar="true"
    page-title="Manage Users"
  >
    <template v-slot:toolbar-content="{ id }">
      <!--begin::Create Project Button-->
      <a
        href="#"
        class="btn btn-sm btn-primary me-5"
        data-bs-toggle="modal"
        :data-bs-target="`#kt_modal_new_user`"
        :id="`kt_create_user_button_${id}`"
      >Create New User</a>
      <!--end::Create Project Button-->
    </template>
  </AgGridFP>
  <KTNewUserModal
    modal-name="new_user"
    modal-title="Add New Internal User"
    :validation-schema="modalValidationSchema"
    :loading="loading"
    @on-modal-submit="onSubmitUser"
    @reset-modal="handleReset"
  >
    <template v-slot:modal-body-content="{}">
      <!--begin::User First Name Input group-->
      <div class="mb-5 fv-row">
        <!--begin::Label-->
        <label class="required fs-5 fw-bold mb-2">First Name</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          type="text"
          class="form-control form-control-solid"
          placeholder
          name="name"
          v-model="newUserData.name"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="name" />
          </div>
        </div>
        <!--end::Input-->
      </div>
      <!--end::User First Name Input group-->

      <!--begin::User Last Name Input group-->
      <div class="mb-5 fv-row">
        <!--begin::Label-->
        <label class="required fs-5 fw-bold mb-2">Last Name</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          type="text"
          class="form-control form-control-solid"
          placeholder
          name="surname"
          v-model="newUserData.surname"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="surname" />
          </div>
        </div>
        <!--end::Input-->
      </div>
      <!--end::User Last Name Input group-->

      <!--begin::User Email Input group-->
      <div class="mb-5 fv-row">
        <!--begin::Label-->
        <label class="required fs-5 fw-bold mb-2">Email</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          type="text"
          class="form-control form-control-solid"
          placeholder
          name="email"
          v-model="newUserData.email"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="email" />
          </div>
        </div>
        <!--end::Input-->
      </div>
      <!--end::User Email Input group-->

      <!--begin::Activation Status Input group-->
      <div class="fv-row mb-5">
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack">
          <!--begin::Label-->
          <div class="me-5">
            <!--begin::Label-->
            <label class="fs-5 fw-bold">Activation Status</label>
            <!--end::Label-->

            <!--begin::Input-->
            <div
              class="fs-7 fw-bold text-gray-400"
            >Activating this user will send a welcome email to the user and start the onboarding process</div>
            <!--end::Input-->
          </div>
          <!--end::Label-->

          <!--begin::Switch-->
          <label class="form-check form-switch form-check-custom form-check-solid form-check-label">
            <!--begin::Input-->
            <Field
              name="isActive"
              type="checkbox"
              id="activationStatus"
              :value="true"
              class="form-check-input"
              :unchecked-value="false"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="isActive" />
              </div>
            </div>
            <!--end::Input-->

            <!--begin::Label-->
            <span class="form-check-label fw-bold text-gray-400">Activate</span>
            <!--end::Label-->
          </label>
          <!--end::Switch-->
        </div>
        <!--begin::Wrapper-->
      </div>
      <!--end::Activation Status Input group-->
    </template>
    <template v-slot:modal-footer></template>
  </KTNewUserModal>
  <KTEditUserAside
    :selected-user="selectedUser"
    aside-name="edit_user"
    aside-title="Edit User"
    :validation-schema="asideValidationSchema"
  >
    <template v-slot:aside-body-content>
      <!--begin::User First Name Input group-->
      <div class="mb-5 fv-row">
        <!--begin::Label-->
        <label class="required fs-5 fw-bold mb-2">First Name</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          type="text"
          class="form-control form-control-solid"
          placeholder
          name="name"
          v-model="editUserData.name"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="name" />
          </div>
        </div>
        <!--end::Input-->
      </div>
      <!--end::User First Name Input group-->

      <!--begin::User Last Name Input group-->
      <div class="mb-5 fv-row">
        <!--begin::Label-->
        <label class="required fs-5 fw-bold mb-2">Last Name</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          type="text"
          class="form-control form-control-solid"
          placeholder
          name="surname"
          v-model="editUserData.surname"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="surname" />
          </div>
        </div>
        <!--end::Input-->
      </div>
      <!--end::User Last Name Input group-->

      <!--begin::User Email Input group-->
      <div class="mb-5 fv-row">
        <!--begin::Label-->
        <label class="required fs-5 fw-bold mb-2">Email</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          type="text"
          class="form-control form-control-solid"
          placeholder
          name="email"
          v-model="editUserData.email"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="email" />
          </div>
        </div>
        <!--end::Input-->
      </div>
      <!--end::User Email Input group-->

      <!--begin::User Roles Input group-->
      <div class="mb-5 fv-row">
        <!--begin::Label-->
        <label class="required fs-5 fw-bold mb-2">Role(s)</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <Multiselect
            name="roles"
            v-model="userRoles.value"
            v-bind="userRoles"
            @close="handleClose"
            @deselect="handleClose"
            required
          ></Multiselect>
          <!-- {{ userRoles.value }} -->
        </div>
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <span>{{ multiSelectMsg }}</span>
          </div>
        </div>
        <!--end::Input-->
      </div>
      <!--end::User Roles Input group-->

      <!--begin::Activation Status Input group-->
      <div class="fv-row mb-5">
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack">
          <!--begin::Label-->
          <div class="me-5">
            <!--begin::Label-->
            <label class="fs-5 fw-bold">Activation Status</label>
            <!--end::Label-->

            <!--begin::Input-->
            <div
              class="fs-7 fw-bold text-gray-400"
            >Activating this user will send a welcome email to the user and start the onboarding process</div>
            <!--end::Input-->
          </div>
          <!--end::Label-->

          <!--begin::Switch-->
          <label class="form-check form-switch form-check-custom form-check-solid form-check-label">
            <!--begin::Input-->
            <Field
              name="isActive"
              type="checkbox"
              id="activationStatus"
              :value="1"
              class="form-check-input"
              :unchecked-value="0"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="isActive" />
              </div>
            </div>
            <!--end::Input-->

            <!--begin::Label-->
            <span class="form-check-label fw-bold text-gray-400">Activate</span>
            <!--end::Label-->
          </label>
          <!--end::Switch-->
        </div>
        <!--begin::Wrapper-->
      </div>
      <!--end::Activation Status Input group-->
    </template>
    <template v-slot:aside-footer></template>
  </KTEditUserAside>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { ManageUsersSetup } from './ManageRolesSetup';
import { ManageUsersModalSetup } from './ManageRolesModalSetup';
import { ManageUsersAsideSetup } from './ManageRolesAsideSetup';
import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import AgGridVerifiedBadge from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridVerifiedBadge.vue";
import AgGridEditButton from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridEditButton.vue";
// import MessageBox from "@/components/notifications/BaseMessageBox.vue";
import KTNewUserModal from "@/components/modals/forms/NewFormModal.vue";
import KTEditUserAside from "@/layout/aside/EditAside.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Multiselect from "@vueform/multiselect";


export default defineComponent({
  name: 'ManageUsers',
  components: {
    AgGridFP,
    // MessageBox,
    KTNewUserModal,
    KTEditUserAside,
    ErrorMessage,
    Field,
    Multiselect
  },
  setup() {
    const frameworkComponents = reactive({
      AgGridVerifiedBadge,
      AgGridEditButton,
    });


    const {
      usersArrayData,
      gridApi,
      colApi,
      columnState,
      bodyOptions,
      customColumnDefs,
      setGridApi,
      setColApi,
      rowValueChanged,
      deleteItem,
      applyState,
      handleSaveState,
      testData,
      selectedUser,
    } = ManageUsersSetup();

    const {


      saveEdit,
      userRoles,
      asideValidationSchema,
      handleClose,
      multiSelectMsg,
    } = ManageUsersAsideSetup(selectedUser);

    const {
      newUserData,
      modalValidationSchema,
      onSubmitUser,
      submitButtonRef,
      loading,
      handleReset
    } = ManageUsersModalSetup();

    return {
      selectedUser,
      usersArrayData,
      gridApi,
      colApi,
      columnState,
      bodyOptions,
      customColumnDefs,
      setGridApi,
      setColApi,
      rowValueChanged,
      deleteItem,
      applyState,
      handleSaveState,
      testData,
      frameworkComponents,
      newUserData,
      modalValidationSchema,
      onSubmitUser,
      submitButtonRef,
      loading,

      saveEdit,
      userRoles,
      asideValidationSchema,
      handleClose,
      multiSelectMsg,
      handleReset

    }
  }
})
</script>