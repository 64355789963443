import {
  ColDef,
  GridApi,
  ColumnApi,
  ValueFormatterParams,
} from "ag-grid-community";
import { computed, onMounted, onUpdated, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useStore } from "vuex";
import { useDebounceFn } from "@vueuse/core";

import { Actions as AggridActions } from "@/store/enums/AggridEnums";
import { Actions as AdminActions } from "@/store/enums/AdminEnums";
import { User } from "@/core/enums/UserEnums";
import { saveState } from "@/core/helpers/aggrid";
import AgGridVerifiedBadge from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridVerifiedBadge.vue";
import AgGridEditButton from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridEditButton.vue";
import AgGridSwitch from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridSwitch.vue";
import { DrawerComponent } from "@/assets/ts/components/_DrawerOptions";
import { User as UserInterface } from "@/core/interfaces/UserInterface";

enum State {
  tableName = "table_name",
  userId = "user_id",
}

export const ManageUsersSetup = () => {
  const { t, te } = useI18n();
  const selectedUser = ref();
  const store = useStore();
  const usersArrayData = ref([
    {
      id: 1,
      UID: 235434,
      su: 1,
      isActive: 1,
      [User.kerberosUsername]: "sb_admin",
      [User.ntlmUsername]: "sb_admin",
      [User.displayName]: "sb_admin",
      [User.langId]: "ee",
      email: "andrus@bauwise.com",
      name: "Andrus",
      surname: "SB",
      [User.emailVerifiedAt]: "2021-09-28 12:57:53",
      tenant: "laravel",
    },
    {
      id: 2,
      UID: 565467,
      su: 0,
      isActive: 0,
      [User.kerberosUsername]: "sb_admin_t_1",
      [User.ntlmUsername]: "sb_admin_t_1",
      [User.displayName]: "sb_admin_t_1",
      [User.langId]: "en",
      email: "kaspar@bauwise.com",
      name: "Kaspar",
      surname: "SB",
      [User.emailVerifiedAt]: null,
      tenant: "tenant2",
    },
  ]);

  const testData = computed(() => {
    return [
      ...usersArrayData.value,
      // Enable below for empty table row below
      // {
      //   id: null,
      //   UID: null,
      //   su: 0,
      //   activation: 0,
      //   [User.kerberosUsername]: null,
      //   [User.ntlmUsername]: null,
      //   [User.displayName]: null,
      //   [User.langId]: null,
      //   email: null,
      //   name: null,
      //   surname: null,
      //   [User.emailVerifiedAt]: null,
      //   tenant: null,
      // },
    ];
  });

  const gridApi = ref<GridApi>();
  const colApi = ref<ColumnApi>();
  const queue = ref<ColDef[]>([]);
  const queueModified = computed(() => {
    return queue.value.map((project) => {
      return project;
    });
  });
  const columnState = computed(() => {
    return store.getters.getStates.filter(
      (state) => state[State.tableName] === "users"
    );
  });

  const bodyOptions = reactive({
    minColWidth: 100,
    editType: "fullRow",
    rowSelection: "single",
    enableRangeSelection: true,
    enableFillHandle: true,
    fillHandleDirection: "y",
    debounceVerticalScrollbar: true,
    // groupMultiAutoColumn: true,
  });

  const translate = (text: string) => {
    return te(text) ? t(text) : text;
  };

  const openDrawer = () => {
    const drawerEl = document.getElementById("kt_drawer_chat") as any;

    console.log(drawerEl);

    DrawerComponent.getInstance(drawerEl).toggle();
  };

  const customColumnDefs = computed((): ColDef[] => {
    return [
      {
        headerName: translate("id"),
        field: "id",
        editable: false,
      },
      {
        headerName: "UID",
        field: "UID",
        editable: false,
      },
      {
        headerName: "isAdmin",
        field: "su",
        editable: false,
      },
      {
        headerName: translate("active"),
        field: "is_active",
        enableValue: true,
        editable: false,
        cellEditorFramework: AgGridSwitch,
        valueFormatter: (params: ValueFormatterParams) => {
          // put the value in bold
          const isActive =
            params.value === true ||
            params.value === "true" ||
            params.value > 0;
          const isInActive =
            params.value === false ||
            params.value === "false" ||
            params.value <= 0;

          if (!isActive && !isInActive) {
            return params.value;
          }

          return `${isActive ? translate("active") : translate("inactive")}`;
        },
        // cellRendererFramework: AgGridSwitch,
      },
      {
        headerName: "Username",
        field: "display_name",
        editable: false,
      },
      {
        headerName: translate("email"),
        field: "email",
        editable: false,
      },
      {
        headerName: translate("firstName"),
        field: "first_name",
        editable: false,
      },
      {
        headerName: translate("lastName"),
        field: "last_name",
        editable: false,
      },
      // {
      //   headerName: translate("verified"),
      //   field: "email_verified_at",
      //   editable: false,
      //   cellRendererFramework: AgGridVerifiedBadge,
      // },
      {
        headerName: translate("preferredLanguage"),
        field: "lang_id",
        editable: false,
      },
      // {
      //   headerName: translate("preferredTenant"),
      //   field: "tenant",
      //   editable: false,
      // },
      {
        headerName: translate("action"),
        editable: false,
        pinned: "right",
        cellRendererFramework: AgGridEditButton,
        cellRendererParams: {
          clicked: function(params) {
            // console.log(params.data);
            selectedUser.value = params.data;
            openDrawer;
          },
        },
      },
    ];
  });

  const setGridApi = (args) => {
    gridApi.value = args;
  };

  const setColApi = (args) => {
    colApi.value = args;
  };

  const rowValueChanged = (args) => {
    if (args.oldData !== args.newData) {
      store
        .dispatch(AggridActions.SET_UNDO_ACTION, {
          type: "success",
          title: "Updating....",
          buttonTitle: "Undo",
          allowUndo: true,
          duration: 5500,
          api: gridApi.value,
        })
        .then((val) => {
          if (val !== "undo") {
            console.log("resolve", val);
            const update = {
              ...args.params.data,
            };
            gridApi.value!.applyTransaction({
              update: [update],
            });
          }
        })
        .catch((err) => {
          console.log("reject", err);
        });
    }
  };

  const transactionFinished = useDebounceFn(() => {
    console.log("finished");
    store
      .dispatch(AggridActions.SET_UNDO_ACTION, {
        type: "success",
        title: "Updating....",
        buttonTitle: "Undo",
        allowUndo: false,
        duration: 5500,
        api: gridApi.value,
      })
      .then((val) => {
        if (val !== "undo") {
          console.log("resolve", val);
          gridApi.value!.applyTransaction({
            update: queue.value,
          });
          store.dispatch(AdminActions.UPDATE_USER, {
            data: queueModified.value,
          });
          queue.value = [];
        }
      })
      .catch((err) => {
        console.log("reject", err);
        queue.value = [];
      });
  }, 3000);

  const cellValueChanged = (args) => {
    if (args.oldValue !== args.newValue) {
      queue.value.push(args.data);
      gridApi.value!.applyTransactionAsync(
        {
          update: queue.value,
        },
        transactionFinished
      );
    }
  };

  const deleteItem = ({ gridApi, selectedRow }) => {
    store
      .dispatch(AggridActions.SET_UNDO_ACTION, {
        type: "success",
        title: "Deleting....",
        buttonTitle: "Cancel",
        allowUndo: true,
        duration: 5500,
        api: gridApi,
      })
      .then((val) => {
        if (val !== "undo") {
          // const update = {
          //   ...selectedRow,
          //   [Project.projectActivation]: false,
          // };
          const update = {
            ...selectedRow,
          };
          gridApi.value!.applyTransaction({
            update: [update],
          });
          gridApi.value!.refreshCells();
          store
            .dispatch(AggridActions.SET_UNDO_ACTION, {
              type: "success",
              title: "Delete Successful",
              buttonTitle: "Undo",
              allowUndo: true,
              duration: 5500,
              api: gridApi,
            })
            .then((val) => {
              if (val === "undo") {
                // const update = {
                //   ...selectedRow,
                //   [Project.projectActivation]: 1,
                // };
                const update = {
                  ...selectedRow,
                };
                gridApi.value!.applyTransaction({
                  update: [update],
                });
                gridApi.value!.refreshCells();
              } else {
                // Call Update method here
                store.dispatch(AdminActions.UPDATE_USER, selectedRow);
                gridApi.value!.refreshCells();
              }
            });
        }
      });
  };

  // const applyState = () => {
  //   if (columnState.value.length === 0) return;
  //   const state = columnState.value[0]["state"];

  //   colApi.value!.applyColumnState({
  //     state: state,
  //     applyOrder: true,
  //   });
  // };

  const applyState = useDebounceFn(() => {
    if (columnState.value.length === 0) return;
    const state = columnState.value[0]["state"];

    colApi.value!.applyColumnState({
      state: state,
      applyOrder: true,
    });
  }, 1000);

  const handleSaveState = useDebounceFn(() => {
    console.log("setState");
    if (usersArrayData.value.length === 0) return;
    const userId = store.getters.currentUser.id;
    const state = colApi.value!.getColumnState();

    const newState = {
      ...columnState.value[0],
      state: state,
      [State.tableName]: "users",
      [State.userId]: userId,
    };
    console.log(newState);
    saveState(newState);
  }, 3000);

  onMounted(() => {
    applyState();

    store.dispatch(AdminActions.GET_ALL_USER).then((data) => {
      console.log(data);
      usersArrayData.value = data;
    });
  });

  return {
    usersArrayData,
    gridApi,
    colApi,
    columnState,
    bodyOptions,
    customColumnDefs,
    setGridApi,
    setColApi,
    rowValueChanged,
    cellValueChanged,
    deleteItem,
    applyState,
    handleSaveState,
    testData,
    openDrawer,
    selectedUser,
  };
};
