<template>
  <div class="badge badge-light-success fw-bolder">{{ getValue() }}</div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, onBeforeMount, ref } from "vue";

export default defineComponent({
  name: "AgGridActions",
  props: ['params'],
  setup(props) {
    const value = ref(props.params.value);


    const isCancelBeforeStart = () => {
      return false;
    };

    const getValue = () => {
      return value.value ? 'Verified' : null;
    };

    return {
      getValue,
      isCancelBeforeStart,
      value,
    };
  },
});
</script>

<style>
#action-switch-label {
  justify-content: center;
}
</style>