<template>
  <!--begin::Modal - New User-->
  <div
    class="modal fade"
    :id="`kt_modal_${modalName}`"
    tabindex="-1"
    aria-hidden="true"
    ref="modal"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Form-->
        <div class="form" :id="`kt_modal_${modalName}_form`">
          <!--begin::Modal header-->
          <div class="modal-header" :id="`kt_modal_${modalName}_header`">
            <!--begin::Modal title-->
            <h2>{{ modalTitle }}</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <button
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              type="reset"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
              </span>
            </button>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div
              class="scroll-y me-n7 pe-7"
              :id="`kt_modal_${modalName}_scroll`"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              :data-kt-scroll-dependencies="`#kt_modal_${modalName}_header`"
              :data-kt-scroll-wrappers="`#kt_modal_${modalName}_scroll`"
              data-kt-scroll-offset="300px"
            >
              <slot name="modal-body-content"></slot>
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <slot name="modal-footer"></slot>
            <!--begin::Button-->
            <button
              type="reset"
              :id="`kt_modal_new_user_cancel`"
              data-bs-dismiss="modal"
              class="btn btn-white me-3"
            >Discard</button>
            <!--end::Button-->

            <!--begin::Button-->
            <button
              ref="submitButtonRef"
              type="submit"
              :id="`kt_modal_new_user_submit`"
              class="btn btn-primary"
              :disabled="loading"
              @click="handleSubmit"
            >
              <span class="indicator-label">Submit</span>
              <span class="indicator-progress">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </div>
        <!--end::Form-->
      </div>
    </div>
  </div>
  <!--end::Modal - New Project-->
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useForm } from "vee-validate";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { User } from "@/core/enums/UserEnums";


export default defineComponent({
  name: "new-form-modal",
  emits: ['onModalSubmit', 'resetModal'],
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    modalName: {
      type: String,
      required: true
    },
    validationSchema: {
      type: Object
    },
  },
  components: {

  },
  setup(props, { emit }) {
    const { values, resetForm, validate } = useForm({
      validationSchema: props.validationSchema,
      initialValues: {
        [User.isActive]: 0
      }
    });
    // const { resetForm } = useForm();
    const modal = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);

    const handleSubmit = async () => {
      const { valid } = await validate();
      if (submitButton.value) {
        loading.value = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      if (valid) {
        emit('onModalSubmit', values);
        loading.value = false;
        submitButton.value?.removeAttribute("data-kt-indicator");
        return;
      }
      loading.value = false;
      submitButton.value?.removeAttribute("data-kt-indicator");

    }

    onMounted(() => {
      modal.value?.addEventListener('hidden.bs.modal', () => {
        emit('resetModal', resetForm)
        removeModalBackdrop();
      })
    });

    onUnmounted(() => {
      modal.value?.removeEventListener('hidden.bs.modal', () => {
        emit('resetModal', resetForm)
        removeModalBackdrop();
      })
    })

    return {
      modal,
      handleSubmit,
      submitButton,
      loading
    }
  },
});
</script>
