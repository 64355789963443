<template>
  <!--begin::Chat drawer-->
  <div
    :id="`kt_drawer_${asideName}`"
    class="bg-white"
    data-kt-drawer="true"
    :data-kt-drawer-name="`${asideName}`"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'300px', 'md': '500px'}"
    data-kt-drawer-direction="end"
    :data-kt-drawer-toggle="`#kt_drawer_edit_toggle`"
    :data-kt-drawer-close="`#kt_drawer_edit_close`"
    ref="ktDrawerRef"
  >
    <!--begin::Messenger-->
    <div class="card w-100" :id="`kt_drawer_${asideName}`">
      <!--begin::Card header-->
      <div class="card-header pe-5" :id="`kt_drawer_${asideName}_header`">
        <!--begin::Title-->
        <div class="card-title">
          <!--begin::User-->
          <div class="d-flex justify-content-center flex-column me-3">
            <a href="#" class="fs-4 fw-bolder text-gray-900 text-hover-primary">{{ asideTitle }}</a>
          </div>
          <!--end::User-->
        </div>
        <!--end::Title-->

        <!--begin::Card toolbar-->
        <div class="card-toolbar">
          <!--begin::Menu-->
          <div class="me-2">
            <button
              class="btn btn-sm btn-icon btn-active-icon-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="top-end"
            >
              <i class="bi bi-three-dots fs-3"></i>
            </button>
            <Dropdown4></Dropdown4>
          </div>
          <!--end::Menu-->

          <!--begin::Close-->
          <div class="btn btn-sm btn-icon btn-active-icon-primary" :id="`kt_drawer_edit_close`">
            <span class="svg-icon svg-icon-2x">
              <inline-svg src="media/icons/duotone/Interface/Close-Square.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Card toolbar-->
      </div>
      <!--end::Card header-->

      <!--begin::Form-->
      <div class="form" :id="`kt_edit_user_form`">
        <!--begin::Card body-->
        <div class="card-body" :id="`kt_drawer_${asideName}_body`">
          <!--begin::Messages-->
          <div
            class="scroll-y me-n5 pe-5"
            ref="editAsideRef"
            data-kt-element="editAside"
            data-kt-scroll="true"
            data-kt-scroll-activate="true"
            data-kt-scroll-height="auto"
            :data-kt-scroll-dependencies="`#kt_drawer_${asideName}_header, #kt_drawer_${asideName}_footer`"
            :data-kt-scroll-wrappers="`#kt_drawer_${asideName}_body`"
            data-kt-scroll-offset="0px"
          >
            <slot name="aside-body-content"></slot>
          </div>
          <!--end::Messages-->
        </div>
        <!--end::Card body-->

        <!--begin::Card footer-->
        <div class="card-footer pt-4" :id="`kt_drawer_${asideName}_footer`">
          <slot name="aside-footer"></slot>
          <!--begin:Toolbar-->
          <div class="d-flex flex-stack">
            <!--begin::Actions-->
            <div class="d-flex align-items-center me-2">
              <!--begin::Save-->
              <button
                @click="handleSubmit"
                ref="submitButton"
                class="btn btn-primary me-2"
                type="button"
                data-kt-element="send"
              >Save</button>
              <!--end::Save-->
              <button class="btn btn-secondary" type="button" :id="`kt_drawer_edit_close`">Cancel</button>

              <button
                class="btn btn-sm btn-icon btn-active-light-primary me-1"
                type="button"
                data-bs-toggle="tooltip"
                title="Coming soon"
              >
                <i class="bi bi-paperclip fs-3"></i>
              </button>
              <button
                class="btn btn-sm btn-icon btn-active-light-primary me-1"
                type="button"
                data-bs-toggle="tooltip"
                title="Coming soon"
              >
                <i class="bi bi-upload fs-3"></i>
              </button>
            </div>
            <!--end::Actions-->

            <!--begin::Cancel-->
            <button
              @click="handleDelete"
              class="btn btn-danger"
              type="button"
              data-kt-element="send"
            >Delete</button>
            <!--end::Cancel-->
          </div>
          <!--end::Toolbar-->
        </div>
        <!--end::Card footer-->
      </div>
    </div>
    <!--end::Messenger-->
  </div>
  <!--end::Chat drawer-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch, watchEffect } from "vue";
import { useForm } from "vee-validate";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import Dropdown4 from "@/components/dropdown/Dropdown4.vue";
import { removeDrawerBackdrop } from "@/core/helpers/dom";


export default defineComponent({
  name: "EditFormAside",
  emits: ['onEditAsideSubmit', 'onEditAsideDelete'],
  props: {
    asideName: {
      type: String,
      required: true,
    },
    asideTitle: {
      type: String,
      required: true
    },
    selectedUser: {
      type: Object,
      required: false
    },
    validationSchema: {
      type: Object
    },
  },
  components: {
    Dropdown4,

  },
  setup(props, { emit }) {
    const { values, setValues, handleReset, validate } = useForm({
      validationSchema: props.validationSchema,
    });
    // setValues({ ...props.selectedUser })

    const editAsideRef = ref<null | HTMLElement>(null);
    const ktDrawerRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);


    const handleSubmit = async () => {
      const { valid } = await validate();
      if (submitButton.value) {
        loading.value = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      if (valid) {
        emit('onEditAsideSubmit', values)
        loading.value = false;
        submitButton.value?.removeAttribute("data-kt-indicator");

      }
    }

    const handleDelete = () => {
      emit('onEditAsideDelete', values)
    }

    onMounted(() => {
      if (!editAsideRef.value || !ktDrawerRef.value) {
        return;
      }

      DrawerComponent.createInstances(`#kt_drawer_${props.asideName}`)

      const drawer = DrawerComponent.getInstance(ktDrawerRef.value)
      console.log(drawer)
      drawer.on('kt.drawer.show', () => {
        setValues({ ...props.selectedUser })
      })

      drawer.on('kt.drawer.after.hidden', () => {
        handleReset();
        removeDrawerBackdrop();
      })

      if (ScrollComponent.getInstance(editAsideRef.value)) {
        ScrollComponent.getInstance(editAsideRef.value).update();
      }
    });

    return {
      editAsideRef,
      handleSubmit,
      handleDelete,
      ktDrawerRef,
      submitButton
    };
  },
});
</script>

<style lang="scss" scoped>
.card-body,
.card-footer {
  padding: 1rem 2.25rem;
}
</style>
