
import { defineComponent, getCurrentInstance, onBeforeMount, ref } from "vue";

export default defineComponent({
  name: "AgGridSwitch",
  props: ['params'],
  setup(props) {
    const switchValue = ref<boolean | number>(props.params.value);
    const value = ref(props.params.value);
    console.log(value.value)

    const handleCheckbox = (e) => {
      if (e.target.checked) {
        switchValue.value = 1;
      } else {
        switchValue.value = 0;
      }
    };

    const isCancelBeforeStart = () => {
      return false;
    };

    const getValue = () => {
      return switchValue.value;
    };

    return {
      // status,
      handleCheckbox,
      getValue,
      switchValue,
      isCancelBeforeStart,
      value,
      // data,
    };
  },
});
